import React, {useEffect, useState} from "react";
import {CardContinueWatching, EmptyState, Layout, Loader, MovieCard} from "../../components";
import {Box, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography} from "@mui/material";
import styles from "./MyEpic.module.css";
import CustomTabs from "../../components/Tabs/Tabs";
import {DataSelectedListType, getDataContinueWatch, getDataMyList, IKey, useDataList} from "./data";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchProfileList, fetchProfileWatchingList} from "../../store/profile/thunks";
import {IProfileList} from "../../store/profile/profileSlice";
import Keys from "../../constants/helper";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";
import {NextIcon} from "../../assets/files";

const MyEpic: React.FC = () => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize();
    const dispatch: AppDispatch = useDispatch();
    const profile_id = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const isMobile = innerWidth < 860;
    type Selected = "LIVE" | "MOVIE" | "SERIAL"
    const [selectedIndex, setSelectedIndex] = useState<IKey>(isMobile ? 0 : 1);
    const [type, setType] = useState<Selected>("MOVIE");
    // Initialize selected based on type to ensure consistency
    const [selected, setSelected] = useState(0);
    const [reset, setReset] = useState<boolean>(false);
    const {profileList, isFetching} = useSelector((state: RootState) => state.profile);
    const dataList = useDataList();

    useEffect(() => {
        // Update type based on selected tab
        if (selected !== undefined) {
            switch (selected) {
                case 0:
                    setType("MOVIE");
                    break;
                case 1:
                    setType("SERIAL");
                    break;
                case 2:
                    setType("LIVE");
                    break;
                default:
                    setType("MOVIE");
                    break;
            }
        }
    }, [selected]);


    const dataSelectedList: DataSelectedListType = {
        1: getDataMyList(t),
        2: getDataContinueWatch(t),
    };

    useEffect(() => {
        if (!profile_id) return;

        if (selectedIndex === 1) {
            dispatch(fetchProfileList(profile_id));
        } else if (selectedIndex === 2) {
            dispatch(fetchProfileWatchingList(profile_id));
        }
    }, [profile_id, selectedIndex, dispatch]);

    const handleListItemClick = (index: IKey) => {
        sessionStorage.setItem("redirect", "true");
        setSelectedIndex(index);
        setReset(!reset);
    };

    const renderListItems = () => (
        <List className={styles.list}>
            {dataList.map((item) => (
                <ListItemButton
                    key={item.key}
                    selected={selectedIndex === item.key}
                    onClick={() => handleListItemClick(item.key as IKey)}
                >
                    <ListItemIcon>
                        <item.icon/>
                    </ListItemIcon>
                    <ListItemText primary={item.name} className={styles.name}/>
                    {isMobile && (
                        <ListItemIcon>
                            <NextIcon/>
                        </ListItemIcon>
                    )}
                </ListItemButton>
            ))}
        </List>
    );

    const filteredContent = profileList && profileList[type] ? profileList[type] : [];

    const renderContent = () => {
        if (isFetching) {
            return <Loader className={styles.loader}/>;
        }

        if (!filteredContent.length  && !isFetching) {
            return <EmptyState label={"Oops... No content available"}/>
        }

        // Filter content by type, safely handle undefined profileList[type]

        return (
            <Stack direction="row" flexWrap="wrap" gap="1rem" mt={5}
                   justifyContent={isMobile ? "center" : "flex-start"}>
                {filteredContent.map((item: IProfileList, index: number) => (
                    <div key={index} className={selectedIndex === 2 ? styles.fullCard : ""}>
                        {selectedIndex === 2 ? <CardContinueWatching data={item}/> : <MovieCard data={item}/>}
                    </div>
                ))}
            </Stack>
        );
    };

    const handleBackMobile = () => {
        if (isMobile) setSelectedIndex(0)
    }

    return (
        <Layout withoutMenu={Boolean(isMobile && selectedIndex !== 0)} handleBack={handleBackMobile}>
            <div className={styles.root}>
                {!isMobile && <Typography variant="h4" className={styles.title}>{t("Aside.myEpic")}</Typography>}
                <Stack direction="row" gap={6} mt={!isMobile ? 8 : 0} alignItems="flex-start">
                    {(selectedIndex === 0 || !isMobile) && renderListItems()}
                    {selectedIndex !== 0 && (
                        <Box sx={{width: "100%"}} display="grid">
                            <CustomTabs list={dataSelectedList[selectedIndex]} reset={reset} withoutAll
                                        setSelected={setSelected}/>
                            {renderContent()}
                        </Box>
                    )}
                </Stack>
            </div>
        </Layout>
    );
};

export default MyEpic;

import React, {useEffect} from "react";
import styles from "./Home.module.css";
import {FilmDescription, Loader} from "../../components";
import {Box, Typography} from "@mui/material";
import FilmLists from "../../components/FilmLists/FilmLists";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {useTranslation} from "react-i18next";
import Layout from "../../components/Layout/Layout";

const Home = () => {
    const {t} = useTranslation("translations");
    const pageName = "HOME"
    const dispatch: AppDispatch = useDispatch();
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);
    useEffect(() => {
        dispatch(fetchPage(pageName))
    }, [dispatch]);

    if (isFetching) return <Loader className={styles.loader}/>;
    const slicedData = dataPage ? dataPage[1]?.contents[4] : null;

    return (
        <Layout>
        <Box>
            <div className={styles.content} style={{backgroundImage: `url(${slicedData?.backdrop_path})`}}>
                <Typography variant="h4" className={styles.title}>{t("Aside.home")}</Typography>
                {slicedData && <FilmDescription data={slicedData}/>}
            </div>
            {dataPage && <FilmLists dataContent={dataPage} pageName={pageName}/>}
        </Box>
        </Layout>
    );
};

export default Home;

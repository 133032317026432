import React, {useEffect, useLayoutEffect, useState} from "react";
import {CustomTabs, LiveDescription, ListTV, Loader, Layout} from "../../components";
import {Box, Typography} from "@mui/material";
import styles from "./LiveTV.module.css";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {fetchCategories} from "../../store/categories/categoriesSlice";
import {IContent, IRelated} from "../../components/Cards/types";
import {setActiveTV, setListTV, setSelectedTV} from "../../store/activeTV/activeTVSlice";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";
import clsx from "clsx"; // Ensure you import the correct type

const LiveTv = () => {
    const {t} = useTranslation("translations");
    const pageName = "LIVE";
    const {innerWidth} = useResize()
    const dispatch: AppDispatch = useDispatch();
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);
    const {list} = useSelector((state: RootState) => state.categories);
    const {activeTV, selectedTV} = useSelector((state: RootState) => state.tvContent);
    const [selected, setSelected] = useState<number>(0);
    const [data, setData] = useState<IRelated[] | null>([])
    const isMobile = innerWidth < 860

    useEffect(() => {
        dispatch(fetchPage(pageName));
    }, [dispatch]);

    useLayoutEffect(() => {
        dispatch(fetchCategories({page: pageName, type: "category_list"}));
    }, [pageName]);


    useEffect(() => {
        if (selected) {
            const filteredContents: IContent[] | any = dataPage?.find(e => e.id === selected)
            setData(filteredContents?.contents)
            dispatch(setListTV(filteredContents?.contents))
        } else {
            const allContents: IRelated[] | any = dataPage ? dataPage.flatMap(item => item.contents) : [];
            setData(allContents)
            dispatch(setListTV(allContents))
            dispatch(setActiveTV(dataPage && dataPage[0]?.contents[0] as any))
        }
    }, [selected, dataPage]);


    if (isFetching) return <Loader className={styles.loader}/>;
    const handleSelectTV = () => {
        dispatch(setSelectedTV(false))
    }

    return (
        <Layout withoutMenu={isMobile && selectedTV} handleBack={handleSelectTV}>
            {!isMobile ?
                <Box
                    className={styles.content}>
                    <Box className={styles.wrapper}>
                        <Typography variant="h4" className={styles.title}>{t("Aside.liveTV")}</Typography>
                        <Box mb={2}><CustomTabs list={list} categories setSelected={setSelected}/></Box>
                        <ListTV data={data}/>
                    </Box>
                    <Box height={"100%"}>
                        {activeTV && <LiveDescription data={activeTV}/>}
                    </Box>
                </Box>
                :
                <Box
                    className={clsx(styles.content, isMobile && styles.contentMobile, (selectedTV && isMobile) && styles.contentMobileSelected)}>
                    {(!selectedTV) &&
                      <Box className={styles.wrapper}>
                        <Typography variant="h4" className={styles.title}>{t("Aside.liveTV")}</Typography>
                        <Box mb={2}><CustomTabs list={list} categories setSelected={setSelected}/></Box>
                        <ListTV data={data}/>
                      </Box>}
                    {(selectedTV) &&
                      <Box height={"100%"}>
                          {activeTV && <LiveDescription data={activeTV}/>}
                      </Box>}
                </Box>}
        </Layout>
    );
};

export default LiveTv;

import React, {useEffect, useState} from "react";
import {
    PlayerBack,
    PlayerMessage,
    PlayerNext,
    PlayerPlay,
    PlayerPlus,
    PlayerPause,
    PlayerRefresh,
    Arrow,
    PlayerLayout, PlayerBackArrow, BurgerClose, EpisodesIcon,
} from "../../assets/files";
import {IconButton, LinearProgress, Popover, Stack, SvgIcon, Typography} from "@mui/material";
import styles from "./VideoPlayer.module.css";
import {formatTime} from "../../constants/formats";
import EpisodeCard from "../Cards/EpisodeCard/EpisodeCard";
import {ISerialData} from "../../store/player/playerSlice";
import {ISeasonsChapters} from "../Cards/types";
import {useDispatch, useSelector} from "react-redux";
import {openVideoPlayer} from "../../store/player/playerSlice";
import {CustomSelect, ListTV} from "../index";
import {OptionAudio, OptionEffects, OptionLanguage, OptionSize} from "../../models/DetailsPage/AudioSetup/data";
import {RootState} from "../../store";
import CustomChip from "../Chip/Chip";
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import {addToList} from "../../services/userAPI";
import Keys from "../../constants/helper";
import useResize from "../../hooks/useResize";
import {enqueueSnackbar} from "notistack";
import {snackbarPosBottomRight} from "../Notification/types";

interface VideoPlayerControlsProps {
    play: boolean;
    progress: number;
    id: number | null;
    duration: number;
    title?: string;
    type?: string;
    togglePlay: () => void;
    handleClose: () => void;
    handleSeek: (newTime: number) => void;
    serialData?: ISerialData | null;
    isVideoPlayerOpen: boolean
}

const VideoPlayerControls: React.FC<VideoPlayerControlsProps> = (props) => {
    const {
        play,
        id,
        progress,
        duration,
        type,
        serialData,
        handleClose,
        isVideoPlayerOpen,
        title = "Name Video", // Default title if not provided
        togglePlay,
        handleSeek,
    } = props
    const {innerWidth} = useResize()
    const dispatch = useDispatch();
    const {t} = useTranslation("translations");
    const profile_id = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const currentTime = (progress / 100) * duration;
    const {listTV} = useSelector((state: RootState) => state.tvContent);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldAnimateOut, setShouldAnimateOut] = useState(false);
    const [currentEpisodeIndex, setCurrentEpisodeIndex] = useState<number>(serialData?.episodeNumber || 0);
    const [controlsVisible, setControlsVisible] = useState(false); // State to manage controls visibility
    const open = Boolean(anchorEl);
    let hideControlsTimeout: NodeJS.Timeout; // Timeout for hiding controls
    const isMobile = innerWidth < 860

    const handleSeekClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const {left, width} = e.currentTarget.getBoundingClientRect();
        const clickX = e.clientX - left;
        const newProgress = (clickX / width) * 100;
        const newTime = (newProgress / 100) * duration;
        handleSeek(newTime);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        if (anchorEl === event.currentTarget) {
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };


    const handleLayout = () => {
        if (isVisible) {
            setShouldAnimateOut(true); // Trigger the slide-out animation
            setTimeout(() => {
                setIsVisible(false); // Hide the component after animation ends
                setShouldAnimateOut(false); // Reset animation state
            }, 500); // Match the duration of the slide-out animation
        } else {
            setIsVisible(true); // Show the component
        }
    };
    // Track current episode number
    const handleEpisodeChange = (seasonNumber: number, episodeNumber: number) => {
        const selectedEpisode = serialData?.chapters?.find(chapter => chapter.chapter_number === episodeNumber);
        if (selectedEpisode?.video) {
            dispatch(openVideoPlayer({
                id: selectedEpisode.video.id,
                title: title,
                type: type,
                videoData: selectedEpisode.video,
                serialData: {
                    seasonNumber,
                    episodeNumber,
                    chapters: serialData?.chapters,
                    episodeTitle: selectedEpisode.title || ""
                }
            }));
            setCurrentEpisodeIndex(episodeNumber); // Update current episode index
            handleLayout()
        }
    };

    const handleNextEpisode = () => {
        if (serialData?.chapters) {
            const nextIndex = currentEpisodeIndex;
            if (nextIndex < serialData?.chapters.length) {
                const nextEpisode = serialData.chapters[nextIndex];
                handleEpisodeChange(serialData.seasonNumber as number, nextEpisode.chapter_number);
            }
        }
    };
    const handlePrevEpisode = () => {
        if (serialData?.chapters) {
            const prevIndex = currentEpisodeIndex - 1;
            if (prevIndex <= serialData?.chapters.length && prevIndex !== 0) {
                const prevEpisode = serialData.chapters[prevIndex - 1];
                handleEpisodeChange(serialData.seasonNumber as number, prevEpisode.chapter_number);
            }
        }
    };

    const handleMouseMove = () => {
        setControlsVisible(true);
        clearTimeout(hideControlsTimeout);
        hideControlsTimeout = setTimeout(() => setControlsVisible(false), 4000); // Hide after 2 seconds of inactivity
    };

    useEffect(() => {
        if (isVideoPlayerOpen) {
            window.addEventListener("mousemove", handleMouseMove);
        }

        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            clearTimeout(hideControlsTimeout); // Clean up the timeout
        };
    }, [isVideoPlayerOpen]); // Only add listener when video player is open

    const handleAddList = async () => {
        try {
            if (profile_id && id) {
                const res = await addToList(profile_id, {content: id});
                res && enqueueSnackbar(`${title} successfully added to list`, {
                    anchorOrigin: snackbarPosBottomRight,
                    variant: "success"
                });
            }
        } catch (error) {
            console.error("Failed to add to list", error);
        }
    };
    return (
        <>
            {(controlsVisible || isVisible) &&
              <div className={styles.title}>
                <IconButton color="primary" onClick={handleClose}>
                  <SvgIcon component={innerWidth > 860 ? Arrow : BurgerClose}/>
                </IconButton>
                <Typography variant="h3" fontSize={innerWidth < 860 ? 14 : 28}>{title}</Typography>
                  {type === "TV" && <CustomChip label={t("Details.live")} color="error"/>}
              </div>
            }
            {controlsVisible &&
              <div className={clsx(styles.wrapperControls, isVisible && styles.wrapperControlsVisible)}>
                  {serialData && <Stack direction={"column"} gap={innerWidth > 860 ? 2 : 1}>
                    <Typography variant={"h3"}>{serialData?.episodeTitle}</Typography>
                    <Typography variant={"h4"}>S:{serialData?.seasonNumber}, E:{serialData?.episodeNumber}</Typography>
                  </Stack>}
                <>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={3}>
                      {innerWidth > 860 ? <Stack direction="row" gap={2}>
                              {/* Play/pause button */}
                              <IconButton color="primary" onClick={togglePlay}>
                                  {play ? <PlayerPause/> : <PlayerPlay/>}
                              </IconButton>
                              {/* Previous Episode Button */}
                              <IconButton color="primary" onClick={handlePrevEpisode}>
                                  <PlayerBack/>
                              </IconButton>
                              <IconButton color="primary" onClick={() => handleSeek(0)}>
                                  <PlayerRefresh/>
                              </IconButton>
                              {/* Next Episode Button */}
                              <IconButton color="primary" onClick={handleNextEpisode}>
                                  <PlayerNext/>
                              </IconButton>
                          </Stack> :
                          <Stack direction="row" gap={1} className={styles.controls_mobile}>
                              {/* Previous Episode Button */}
                              <IconButton color="primary" onClick={handlePrevEpisode}>
                                  <PlayerBack/>
                              </IconButton>
                              {/* Next Episode Button */}
                              <IconButton color="primary" onClick={handleNextEpisode}>
                                  <PlayerNext/>
                              </IconButton>
                              <IconButton color="primary" onClick={togglePlay}>
                                  {play ? <PlayerPause/> : <PlayerPlay/>}
                              </IconButton>
                              <IconButton color="primary" onClick={() => handleSeek(0)}>
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                      <PlayerRefresh/>
                                      <Typography variant={"body2"}>
                                          Reset
                                      </Typography>
                                  </Stack>
                              </IconButton>
                              {serialData?.seasonNumber &&
                                <IconButton color="primary" onClick={handleLayout}>
                                  <Stack direction={"row"} gap={1} alignItems={"center"}>
                                    <EpisodesIcon/>
                                    <Typography variant={"body2"}>
                                      Episodes
                                    </Typography>
                                  </Stack>
                                </IconButton>}
                          </Stack>
                      }
                      {innerWidth > 860 &&
                        <Stack direction="row" gap={2}>
                          <IconButton color="primary" onClick={handleAddList}>
                            <PlayerPlus/>
                          </IconButton>
                          <IconButton color="primary" onClick={handleClick}>
                            <PlayerMessage/>
                          </IconButton>
                            {(type === "TV" || type === "SERIE") &&
                              <IconButton color="primary" onClick={handleLayout}>
                                <PlayerLayout/>
                              </IconButton>
                            }
                        </Stack>}
                  </Stack>

                    {/* Progress bar */}
                  <div className={styles.progress_wrapper} onClick={handleSeekClick}>
                    <LinearProgress variant="determinate" value={progress} className={styles.progress}/>
                  </div>

                    {/* Current time and duration */}
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mt={1} mb={1}>
                    <Typography variant="h4">{formatTime(currentTime)}</Typography>
                    <Typography variant="h4">{formatTime(duration)}</Typography>
                  </Stack>
                </>
              </div>}
            {(serialData?.seasonNumber && isVisible) && (
                <div
                    className={`${styles.serial_episodesWrapper} ${shouldAnimateOut ? styles.animateOut : styles.animateIn}`}>
                    <IconButton color="primary" onClick={handleLayout}>
                        <PlayerBackArrow/>
                    </IconButton>
                    <div className={styles.serial_episodes}>
                        <div className={styles.episodesWrapper}>
                            {serialData.chapters?.map((e: ISeasonsChapters, i) => (
                                <EpisodeCard
                                    key={i}
                                    data={e}
                                    onEpisodeChange={handleEpisodeChange}
                                    activeNumber={serialData.episodeNumber as number}
                                    seasonNumber={serialData.seasonNumber as number}
                                    isMobileControls={isMobile}
                                />
                            ))}
                        </div>

                    </div>
                </div>
            )}
            {(type === "TV" && isVisible) &&
              <div
                className={`${styles.serial_episodesWrapper} ${shouldAnimateOut ? styles.animateOut : styles.animateIn}`}>
                <IconButton color="primary" onClick={handleLayout}>
                  <PlayerBackArrow/>
                </IconButton>
                <div className={styles.serial_episodes}>
                  <div className={styles.episodesWrapper}>
                    <ListTV data={listTV as any} forPlayer/>
                  </div>
                </div>
              </div>
                //     <div className={styles.tvList}>
                // <ListTV data={listTV as any} forPlayer/>
                //
                // </div
                // >
            }
            <Popover
                sx={{margin: "-8rem -2rem"}}
                onClose={() => setAnchorEl(null)}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
            >
                <div className={styles.popoverAudio}>
                    <div className={styles.popoverAudioForm}>
                        <CustomSelect label={"Audio language"} value={"english"} options={OptionAudio}/>
                        <CustomSelect label={"Subtitles language"} value={"english"} options={OptionLanguage}/>
                        <CustomSelect label={"Subtitles size"} value={"medium"} options={OptionSize}/>
                        <CustomSelect label={"Subtitles effects"} value={"no-effect"} options={OptionEffects}/>
                    </div>
                </div>
            </Popover>
        </>
    );
};

export default VideoPlayerControls;

import React from "react";
import {IRelated} from "../Cards/types"; // Make sure you have the right import
import {Box, Typography} from "@mui/material";
import styles from "./ListTV.module.css";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import {setActiveTV, setSelectedTV} from "../../store/activeTV/activeTVSlice";
import {RootState} from "../../store";
import {openVideoPlayer} from "../../store/player/playerSlice";
import {getContentDetails} from "../../services/contentAPI";
import helper from "../../constants/helper";
import CustomChip from "../Chip/Chip";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

interface IProps {
    data: IRelated[] | null; // Ensure you use the correct type for your data
    forPlayer?: boolean
}

const ListTv: React.FC<IProps> = ({data, forPlayer}) => {
    const {innerWidth} = useResize()
    const isMobile = innerWidth < 860
    const {t} = useTranslation("translations")
    const {activeTV} = useSelector((state: RootState) => state.tvContent);
    const dispatch = useDispatch();
    const profileID = localStorage.getItem(helper.EPIC_PROFILE_ID);


    const handleSelect = async (content: IRelated) => {
        dispatch(setActiveTV(content));
        isMobile &&  dispatch(setSelectedTV(true))
        if (forPlayer && profileID) {
            const res = await getContentDetails(profileID as string, Number(content.id));
            if (res.video) {
                dispatch(openVideoPlayer({
                    id: Number(res.id),
                    title: res?.title || "",
                    type: "TV",
                    videoData: res.video,
                }));
            }
        }
    };


    return (
        <Box className={clsx(styles.wrapper, forPlayer && styles.wrapperPlayer)}>
            {data && data.map((content, index) => (
                <div
                    className={clsx(styles.card, activeTV?.id === content.id && styles.active, forPlayer && styles.cardBlur,
                        (activeTV?.id === content.id && forPlayer) && styles.activeBlur
                    )} key={index}
                    onClick={() => handleSelect(content)}>
                    <img src={content.backdrop_path} alt={"poster"} loading={"lazy"}/>
                    <div>
                        {activeTV?.id === content.id && <div className={clsx(styles.chipLiveProgram)}>
                          <CustomChip label={t("Details.live")} color="error"/>
                        </div>}
                        <Typography variant={"h2"}>{content.title}</Typography>
                        <Typography variant={"h4"}>{content.synopsis}</Typography>
                    </div>
                </div>
            ))}
        </Box>
    );
};

export default ListTv;

import React from "react";
import {AmexIcon, DiscoverIcon, MasterCardIcon, VisaIcon} from "../assets/files";
import {DefaultCard} from "../assets/files/PaymentsIcons";

export const getCardIcon = (cardType: string | null): React.ReactNode => {
  switch (cardType) {
    case "Visa":
      return <VisaIcon />;
    case "MasterCard":
      return <MasterCardIcon />;
    case "AmericanExpress":
      return <AmexIcon />;
    case "Discover":
      return <DiscoverIcon />;
    default:
      return <DefaultCard />;
  }
};

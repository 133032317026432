import React, {ReactNode} from "react";
import styles from "./Layout.module.css";
import {Box} from "@mui/material";
import {Aside, AsideMobile, Loader} from "../index";
import clsx from "clsx";
import useResize from "../../hooks/useResize";


type LayoutProps = {
    children: ReactNode;
    isLoading?: boolean;
    withoutMenu?: boolean,
    style?: string
    route?: string;
    handleBack?: () => void
}
const Layout = ({children, isLoading, style, route,handleBack, withoutMenu = false}: LayoutProps) => {
    const {innerWidth} = useResize()

    return (
        <div className={styles.root} >
            {innerWidth > 860 ?
                <Box className={styles.aside}>
                    <Aside withoutMenu={withoutMenu} route={route}/>
                </Box> :
                <Box className={styles.aside_mobile} >
                    <AsideMobile withoutMenu={withoutMenu} handleBack={handleBack}/>
                </Box>
            }
            <Box className={clsx(styles.main, withoutMenu && styles.mainWithoutMenu)} style={{backgroundImage: style}}>
                {isLoading ? <Loader/> : children}
            </Box>

        </div>
    );
}
export default Layout;

import {createAsyncThunk} from "@reduxjs/toolkit";
import {getList, getProfiles, getProfilesById, getWatchingList} from "../../services/userAPI";
import {IMyList, IProfile} from "./profileSlice";

export const fetchUserList = createAsyncThunk<Array<IProfile>>(
    "users/fetchUserList",
    async () => {
        return await getProfiles();
    }
);

export const fetchUserProfile = createAsyncThunk<IProfile, string | number>(
    "users/fetchUserProfile",
    async (id) => {
        return await getProfilesById(id);
    }
);
export const fetchProfileList = createAsyncThunk<IMyList, string>(
    "users/fetchProfileList",
    async (id: string) => {
        return await getList(id);
        // const response = await getList(id); // Make sure `getList` returns the correct type
        // return response;
    }
);
export const fetchProfileWatchingList = createAsyncThunk<IMyList, string>(
    "users/fetchProfileWatchingList",
    async (id: string) => {
        return await getWatchingList(id);
        // const response = await getList(id); // Make sure `getList` returns the correct type
        // return response;
    }
);

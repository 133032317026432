import React from "react";
import styles from "./styles.module.css"
import {Button, CustomInput} from "../../index";
import {Divider, Grid, Stack, Typography} from "@mui/material";
import {SmallLogo} from "../../../assets/files";
import {getCardIcon} from "../../../utils/getCardIcon";
import useResize from "../../../hooks/useResize";
import {useTranslation} from "react-i18next";

const ProfileEdit = () => {
    const {t} = useTranslation()
    const {innerWidth} = useResize()
    const isMobile = innerWidth < 860
    return (
        <div className={styles.content_list}>
            <Stack component={"span"} direction={"row"} alignItems={"center"} gap={4}
                   mb={6}>
                <SmallLogo/>
                <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                    <Typography variant={"subtitle2"} fontSize={16}>
                        Your account remains active until:
                    </Typography>
                    <Typography variant={"h4"}>
                        May 29, 2025.
                    </Typography>
                </Stack>
            </Stack>

            {!isMobile && <Divider/>}

            <Grid container spacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Stack width={"100%"}>
                        <CustomInput value={""} label={"Email"} placeholder={"Email"}/>
                        <span className={styles.subtitle_input}>Email is not verified</span>
                    </Stack>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Stack width={"100%"}>
                        <CustomInput value={""} label={"Phone number"} placeholder={"Phone number"}/>
                        <span className={styles.subtitle_input}>Email is not verified</span>
                    </Stack>
                </Grid>

            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <CustomInput type={"password"} value={""} label={"Old password"}
                                 placeholder={"Old password"}/>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <CustomInput type={"password"} value={""} label={"New password"} placeholder={"New password"}/>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <CustomInput type={"password"} value={""} label={"Confirm new password"}
                                 placeholder={"Confirm new password"}/>
                </Grid>
            </Grid>
            <Grid container justifyContent={"end"} margin={"16px 0"}>
                <Grid item xs={isMobile ? 12 : 6} padding={0}>
                    <Button color={"error"} variant={"contained"} fullWidth>{t("Buttons.saveChanges")}</Button>
                </Grid>
            </Grid>
            <Divider/>
            <Grid container spacing={2}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Stack direction={"column"} gap={3}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>{getCardIcon("Visa")}
                            <span> •••• •••• ••••  0032</span>
                        </Stack>
                        <Typography variant={"subtitle2"} fontSize={16}>Next payment date: May 8, 2024.</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Button color="secondary" variant="contained" fullWidth>Change the payment method</Button>
                </Grid>
            </Grid>
            <Divider/>
            <Grid container justifyContent={"end"} margin={"8px 0 16px"}>
                <Grid item xs={isMobile ? 12 : 6} padding={0}>
                    <Button color="secondary" variant="contained" fullWidth>Cancel your subscription</Button>
                </Grid>
            </Grid>
        </div>
    );
};

export default ProfileEdit;

import React from "react";
import {Typography} from "@mui/material";
import styles from "./EmptyState.module.css"

interface IProps {
    label: string
}

const EmptyState: React.FC<IProps> = (props) => {
    const {label} = props
    return (
        <div className={styles.root}>
            <Typography variant={"h4"}>{label}</Typography>
        </div>
    );
};

export default EmptyState;

import React, {useEffect} from "react";
import styles from "./Movies.module.css";
import {FilmDescription, Layout, Loader} from "../../components";
import {Box, Typography} from "@mui/material";
import FilmLists from "../../components/FilmLists/FilmLists";
import {AppDispatch, RootState} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {fetchPage} from "../../store/contents/thunks";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

const Movies = () => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const dispatch: AppDispatch = useDispatch();
    const pageName = "MOVIE"
    const {dataPage, isFetching} = useSelector((state: RootState) => state.contents);

    useEffect(() => {
        dispatch(fetchPage(pageName));
    }, [dispatch]);

    if (isFetching) return <Loader className={styles.loader}/>;

    const slicedData = dataPage ? dataPage[3]?.contents[0] : null;

    return (
        <Layout>
            <Box>
                <div className={styles.content} style={{backgroundImage: `url(${slicedData?.backdrop_path})`}}>
                    {innerWidth > 860 &&
                      <Typography variant="h4" className={styles.title}>{t("Aside.movie")}</Typography>}
                    {slicedData && <FilmDescription data={slicedData}/>}
                </div>
                {dataPage && <FilmLists dataContent={dataPage} pageName={pageName}/>}
            </Box>
        </Layout>

    );
};

export default Movies;

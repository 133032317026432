import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {fetchProfileList, fetchProfileWatchingList, fetchUserList, fetchUserProfile} from "./thunks";

export interface IProfile {
    avatar: string | any;
    id: number;
    language: string | null;
    name: string;
    is_kids: boolean
}

export interface IProfileList {
    id: number,
    title: string,
    year: number,
    country: string | null,
    duration: string,
    director: string,
    rating: number,
    poster_path: string,
    backdrop_path: string,
    type: string,
    synopsis: string,
    genders: string,
    classification: null | any
}


export interface IMyList {
    LIVE: Array<IProfileList>;
    MOVIE: Array<IProfileList>;
    SERIAL: Array<IProfileList>;
}

interface UsersState {
    isFetching: boolean;
    isFetched: boolean;
    isLoaded: boolean;
    list: Array<IProfile>;
    profileList: IMyList | null;
    profile: IProfile | null;
}

const initialState: UsersState = {
    isFetching: false,
    isFetched: false,
    isLoaded: false,
    list: [],
    profileList: null,
    profile: null,
};

const profileSlice = createSlice({
    name: "users",
    initialState,
    reducers: {
        resetProfile(state) {
            state.profile = null;
            state.isFetched = false;
            state.isLoaded = false;
            state.isFetching = false;
        },
    },
    extraReducers: (builder) => {
        builder
            // Handling the fetching of the user list
            .addCase(fetchUserList.pending, (state) => {
                state.isFetching = true;
                state.list = [];
            })
            .addCase(fetchUserList.fulfilled, (state, action: PayloadAction<Array<IProfile>>) => {
                state.list = action.payload;
                state.isFetching = false;
                state.isFetched = true;
            })
            .addCase(fetchUserList.rejected, (state) => {
                state.isFetching = false;
                state.list = [];
            })
            // Handling the fetching of a single user profile
            .addCase(fetchUserProfile.pending, (state) => {
                state.isFetching = true;
                state.profile = null;
            })
            .addCase(fetchUserProfile.fulfilled, (state, action: PayloadAction<IProfile>) => {
                state.profile = action.payload;
                state.isFetching = false;
                state.isLoaded = true;
            })
            .addCase(fetchUserProfile.rejected, (state) => {
                state.isFetching = false;
                state.profile = null;
            })
            .addCase(fetchProfileList.pending, (state) => {
                state.isFetching = true;
                state.profileList = null;
            })
            .addCase(fetchProfileList.fulfilled, (state, action: PayloadAction<IMyList>) => {
                state.profileList = action.payload;
                state.isFetching = false;
                state.isFetched = true;
            })
            .addCase(fetchProfileList.rejected, (state) => {
                state.isFetching = false;
                state.profile = null;
            })
            .addCase(fetchProfileWatchingList.pending, (state) => {
                state.isFetching = true;
                state.profileList = null;
            })
            .addCase(fetchProfileWatchingList.fulfilled, (state, action: PayloadAction<IMyList>) => {
                state.profileList = action.payload;
                state.isFetching = false;
                state.isFetched = true;
            })
            .addCase(fetchProfileWatchingList.rejected, (state) => {
                state.isFetching = false;
                state.profile = null;
            });
    },
});


export const {resetProfile} = profileSlice.actions;

export default profileSlice.reducer;

import React, {useEffect, useRef, useState} from "react";
import ReactPlayer from "react-player";
import screenfull from "screenfull";
import VideoPlayerControls from "./VideoPlayerControls";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {
    resetVideo,
    setDuration,
    setFullscreen,
    setPlay,
    setProgress,
    closeVideoPlayer,
} from "../../store/player/playerSlice";
import {Fade, Modal} from "@mui/material";
import styles from "./VideoPlayer.module.css";
import {continueWatchingList} from "../../services/userAPI";
import Keys from "../../constants/helper";
import {Loader} from "../index";

const VideoPlayer: React.FC = () => {
    const profile_id = localStorage.getItem(Keys.EPIC_PROFILE_ID);
    const dispatch: AppDispatch = useDispatch();
    const playerRef = useRef<ReactPlayer | null>(null);
    const {
        id,
        play,
        type,
        progress,
        duration,
        isFullscreen,
        videoData,
        title,
        serialData,
        isVideoPlayerOpen,
    } = useSelector((state: RootState) => state.videoPlayer);
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        setTimeout(() => {
            videoData.url && setLoading(false)
        }, 2000)
    }, [duration]);

    const togglePlayState = () => {
        dispatch(setPlay(!play));
    };

    const handleProgress = (state: { played: number }) => {
        const newProgress = state.played * 100;
        dispatch(setProgress(newProgress));
    };

    const handleDuration = (duration: number) => {
        dispatch(setDuration(duration));
    };

    const handleSeek = (newTime: number) => {
        if (playerRef.current) {
            playerRef.current.seekTo(newTime); // Seek to the new time in seconds
        }
    };

    const handleFullscreenChange = () => {
        dispatch(setFullscreen(!!document.fullscreenElement));
    };

    useEffect(() => {
        const handleKeydown = (event: KeyboardEvent) => {
            if (event.key === "Escape" && isFullscreen) {
                handleClose(); // Close player when Escape is pressed in fullscreen
            }
            if (event.key === " ") {
                event.preventDefault(); // Prevent scrolling the page when space is pressed
                togglePlayState(); // Toggle play/pause on spacebar press
            }
        };

        const handlePopState = () => {
            handleClose(); // Close player when back arrow is clicked
        };

        if (screenfull.isEnabled) {
            screenfull.request()
            screenfull.on("change", handleFullscreenChange);
            window.addEventListener("keydown", handleKeydown);
            window.addEventListener("popstate", handlePopState); // Listen for popstate
        }

        return () => {
            if (screenfull.isEnabled) {
                screenfull.off("change", handleFullscreenChange);
            }
            window.removeEventListener("keydown", handleKeydown);
            window.removeEventListener("popstate", handlePopState); // Clean up popstate listener
        };
    }, [isFullscreen, dispatch, play]); // Include play in dependency array

    useEffect(() => {
        if (isVideoPlayerOpen) {
            dispatch(setPlay(true)); // Autoplay when modal opens
        } else {
            dispatch(resetVideo()); // Reset video when component unmounts
        }
    }, [isVideoPlayerOpen, dispatch]);

    // Handle loading states
    const handleClose = async () => {
        if (type !== "LIVE") {
            try {
                dispatch(closeVideoPlayer());
                screenfull.exit();
                await continueWatchingList(profile_id as string, {content: id as number, minute: duration});
            } catch (err) {
                console.log("err", err);
            }
        } else {
            dispatch(closeVideoPlayer());
            screenfull.exit();
        }

    };

    // Mouse movement handler to toggle controls visibility

    return (
        <Modal
            open={isVideoPlayerOpen}
            onClose={() => dispatch(closeVideoPlayer())}
            closeAfterTransition
            className={styles.modal}
        >
            <Fade in={isVideoPlayerOpen}>

                <div className={styles.modalContent}>
                    {!loading ? <>
                            <div onClick={togglePlayState}>
                                <ReactPlayer
                                    id={videoData.id}
                                    ref={playerRef}
                                    url={videoData.url}
                                    playing={play}
                                    loop
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    onProgress={handleProgress}
                                    onDuration={handleDuration}
                                />
                            </div>
                            <VideoPlayerControls
                                id={id}
                                serialData={serialData}
                                handleClose={handleClose}
                                play={play}
                                type={type}
                                isVideoPlayerOpen={isVideoPlayerOpen}
                                progress={progress}
                                duration={duration}
                                title={title}
                                togglePlay={togglePlayState}
                                handleSeek={handleSeek} // Pass handleSeek to controls
                            />
                        </>
                        : <Loader className={styles.loader}/>}
                </div>
            </Fade>
        </Modal>
    );
};

export default VideoPlayer;

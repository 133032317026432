import React, {useEffect, useState} from "react";
import {
    AppBar,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Divider,
    Modal, IconButton, Popover, Typography,
} from "@mui/material";
import {Arrow, Check, Chevron, EditIcon, Logo, LogoKids, SmallLogo, SmallLogoKids} from "../../assets/files";
import {useNavData, useNavKidsData} from "./IAside";
import styles from "./Aside.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import {routes} from "../../routes/routes";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../store";
import {fetchUserList, fetchUserProfile} from "../../store/profile/thunks";
import helper from "../../constants/helper";
import Avatar from "../../assets/images/Avatar.png";
import {setProfileID, setRole, setUser} from "../../store/reducer";
import {resetProfile} from "../../store/profile/profileSlice";
import Keys from "../../constants/helper";
import clsx from "clsx";
import {useTranslation} from "react-i18next";

interface IProps {
    withoutMenu?: boolean,
    route?: string
}

export interface IProfile {
    avatar: {
        file: string
        id: number
    };
    id: number;
    is_kids: boolean;
    language: string | null;
    name: string;
}

const Aside: React.FC<IProps> = ({withoutMenu = false, route}) => {
    const {t} = useTranslation("translations");
    const role = localStorage.getItem(helper.EPIC_USER_ROLE);
    const profileData = localStorage.getItem(helper.EPIC_USER_DATA);
    const profileID = localStorage.getItem(helper.EPIC_PROFILE_ID);
    const dispatch: AppDispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = useState<boolean>(false);
    const handleDrawerToggle = () => !withoutMenu && setOpen(prevOpen => !prevOpen);
    const {profile, isLoaded, isFetched, list} = useSelector((state: RootState) => state.profile);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const openPopup = Boolean(anchorEl);
    const navData = useNavData()
    const navDataKIDS = useNavKidsData()

    const dataToMap = role === "KIDS" ? navDataKIDS : navData;

    useEffect(() => {
        if (isLoaded && !withoutMenu) {
            dispatch(fetchUserList());
            return
        }
    }, [isFetched, profileID, isLoaded, profileData, dispatch]);

    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        if (open) {
            if (anchorEl === event.currentTarget) {
                setAnchorEl(null);
            } else {
                setAnchorEl(event.currentTarget);
            }
        }
    };
    console.log(123)

    const handleNavigateToEdit = () => {
        navigate(routes.profile)
        dispatch(resetProfile())
        localStorage.removeItem(Keys.EPIC_PROFILE_ID);
        localStorage.removeItem(Keys.EPIC_USER_ROLE);
    }

    const handleChangeLink = (path?: string) => {
        if (path) {
            setOpen(false);
            navigate(path);

        }
    };
    useEffect(() => {
        if (!isLoaded && profileID) {
            dispatch(fetchUserProfile(profileID));
        }
    }, [!isLoaded, profileID, dispatch]);


    const handleBack = () => {
        if (!route) {
            window.history.back(); // Go back to the previous page
        } else {
            navigate(route ?? routes.home); // Navigate to home if no previous page exists
        }
    }

    const handleChangeProfile = (e: IProfile) => {
        if (e) {
            console.log("e", e)
            dispatch(setProfileID(e?.id.toString()))
            dispatch(setUser(e))
            dispatch(setRole(e.is_kids ? "KIDS" : "USER"))
            window.location.reload();
        }

    }
    const renderContent = () => (
        <Box className={styles.box}>
            <AppBar className={clsx(styles.logo, open && styles.logoOpened)}>
                {open ? <IconButton>{role == "KIDS" ? <LogoKids size={145} height={48}/> :
                        <Logo size={145} height={48}/>}
                    </IconButton> :
                    <IconButton onClick={() => navigate(routes.home)}>
                        {role === "KIDS" ? <SmallLogoKids/> : <SmallLogo/>}
                    </IconButton>}
            </AppBar>
            {withoutMenu ? <div className={styles.arrow} onClick={handleBack}><Arrow/></div> :
                <Drawer
                    variant={"permanent"}
                    open={open}
                    onClose={handleDrawerToggle}
                >
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                                borderRadius: 1,
                                opacity: 1,
                            }} >
                                <ListItemIcon
                                    onClick={handleDrawerToggle}
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "0",
                                        justifyContent: "center",
                                    }}
                                >
                                    <img src={profile?.avatar?.file || Avatar} alt={"file"} loading={"lazy"}
                                         className={styles.profile_image}/>

                                </ListItemIcon>
                                <ListItemText
                                    onClick={handleOpenPopover}
                                    className={styles.text}
                                    primary={profile?.name}
                                    sx={{
                                        marginLeft: open ? "-8px" : "0",
                                        opacity: open ? 1 : 0,
                                        maxWidth: open ? "200px" : "0px", // Adjust maxWidth as needed
                                        transition: "opacity 0.3s ease, max-width 0.3s ease",
                                        width: "80px",
                                        marginRight: open ? ".5rem" : 0
                                    }}
                                />
                                <div onClick={handleOpenPopover}>
                                    {open && <Chevron  rotate={openPopup ? 180 : 0}/>}
                                </div>
                            </ListItemButton>
                        </ListItem>
                        {dataToMap.map((item, index) => (
                            <React.Fragment key={item.key}>
                                {index === navData.length - 9 && <Divider style={{opacity: .16}}/>}
                                <ListItem disablePadding>
                                    <ListItemButton
                                        sx={{
                                            minHeight: 48,
                                            justifyContent: open ? "initial" : "center",
                                            px: 2.5,
                                            borderRadius: 1,
                                        }}
                                        selected={location?.pathname === item.path}
                                        onClick={() => handleChangeLink(item.path)}
                                    >
                                        <ListItemIcon
                                            sx={{
                                                minWidth: 0,
                                                mr: open ? 3 : "0",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <item.icon/>
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={item.label}
                                            sx={{
                                                opacity: open ? 1 : 0,
                                                maxWidth: open ? "200px" : "0px", // Adjust maxWidth as needed
                                                transition: "opacity 0.3s ease, max-width 0.3s ease",
                                                overflow: "hidden", // Prevent text overflow when hidden
                                                whiteSpace: "nowrap" // Prevent text wrapping
                                            }}
                                        />
                                    </ListItemButton>
                                </ListItem>
                                {index === navData.length - 2 && <Divider style={{opacity: .16,}}/>}
                            </React.Fragment>
                        ))}
                    </List>
                </Drawer>}
            <Popover
                onClose={() => setAnchorEl(null)}
                open={openPopup}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}>
                <div className={styles.popoverAudio}>
                    {list.map((e) => (
                        <div key={e.id} className={e.id === profile?.id ? styles.active : ""}
                             onClick={() => handleChangeProfile(e)}>
                            <img src={e.avatar?.file || Avatar} alt={"file"}/>
                            <Typography variant={"h5"}>{e.name}</Typography>
                            {e.id === profile?.id && <Check/>}
                        </div>
                    ))}
                    <div className={styles.edit} onClick={handleNavigateToEdit}>
                        <div><EditIcon/></div>
                        <Typography variant={"h5"}>{t("Authorization.editProfiles")}</Typography>
                    </div>
                </div>
            </Popover>
        </Box>
    );
    return (<>{open ? (
        <Modal open={open} onClose={handleDrawerToggle}>{renderContent()}</Modal>) : (renderContent())}</>);
};

export default Aside;

import React, {useEffect, useState} from "react";
import {Tab, Tabs} from "@mui/material";
import clsx from "clsx";
import styles from "./Tabs.module.css";
import {Category} from "../../types/categories";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";

interface IProps {
    list: Category[];
    setSelected?: (value: number | any) => void; // Specify the function type
    setLoading?: (loading: boolean) => void; // Specify the function type
    withoutAll?: boolean;
    reset?: boolean;
    children?: React.ReactNode;
    categories?: boolean;
}

const CustomTabs: React.FC<IProps> = ({
                                          list,
                                          withoutAll = false,
                                          categories = false,
                                          reset,
                                          setLoading,
                                          setSelected,
                                      }) => {
    const [value, setValue] = useState<number>(0);
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        event.preventDefault();
        setValue(newValue);
        setSelected?.(newValue); // Only call if setSelected is defined
        setLoading?.(true); // Only call if setLoading is defined
    };

    useEffect(() => {
        setValue(0);
    }, [reset]);

    return (
        <Tabs
            className={clsx(categories && styles.tabs_categories)}
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons={innerWidth > 860}
            allowScrollButtonsMobile
            aria-label="scrollable force tabs example"
            sx={{flexGrow: 1}}
        >
            {!withoutAll && <Tab label={t("Buttons.all")} value={0}/>}
            {list.map((category) => (
                <Tab key={category.id} label={category.name} value={category.id}/>
            ))}
        </Tabs>
    );
};

export default CustomTabs;

export const PlayerPlay = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.1665 4.1576C4.1665 3.3483 4.1665 2.94365 4.33525 2.72059C4.48225 2.52626 4.70694 2.40601 4.95017 2.39149C5.22937 2.37482 5.56606 2.59928 6.23944 3.0482L15.0024 8.89018C15.5588 9.26111 15.837 9.44658 15.934 9.68035C16.0187 9.88473 16.0187 10.1144 15.934 10.3188C15.837 10.5526 15.5588 10.738 15.0024 11.109L6.23944 16.951C5.56606 17.3999 5.22937 17.6243 4.95017 17.6077C4.70694 17.5931 4.48225 17.4729 4.33525 17.2786C4.1665 17.0555 4.1665 16.6509 4.1665 15.8416V4.1576Z"
            fill="white"/>
    </svg>
);
export const PlayerPause = () => (
    <svg  width="20" height="20" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.75 3H11.25C10.8358 3 10.5 3.33579 10.5 3.75V14.25C10.5 14.6642 10.8358 15 11.25 15H12.75C13.1642 15 13.5 14.6642 13.5 14.25V3.75C13.5 3.33579 13.1642 3 12.75 3Z"
            fill="white"/>
        <path
            d="M6.75 3H5.25C4.83579 3 4.5 3.33579 4.5 3.75V14.25C4.5 14.6642 4.83579 15 5.25 15H6.75C7.16421 15 7.5 14.6642 7.5 14.25V3.75C7.5 3.33579 7.16421 3 6.75 3Z"
            fill="white"/>
        <path
            d="M12.75 3H11.25C10.8358 3 10.5 3.33579 10.5 3.75V14.25C10.5 14.6642 10.8358 15 11.25 15H12.75C13.1642 15 13.5 14.6642 13.5 14.25V3.75C13.5 3.33579 13.1642 3 12.75 3Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M6.75 3H5.25C4.83579 3 4.5 3.33579 4.5 3.75V14.25C4.5 14.6642 4.83579 15 5.25 15H6.75C7.16421 15 7.5 14.6642 7.5 14.25V3.75C7.5 3.33579 7.16421 3 6.75 3Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const PlayerBack = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4.1665 15.8327V4.16602M13.6669 5.06569L8.80129 8.95819C8.35641 9.31409 8.13398 9.49204 8.05345 9.70702C7.98284 9.89551 7.98284 10.1032 8.05345 10.2917C8.13398 10.5067 8.35641 10.6846 8.80129 11.0405L13.6669 14.933C14.3604 15.4878 14.7071 15.7652 14.9989 15.7655C15.2527 15.7658 15.4928 15.6504 15.6511 15.452C15.8332 15.224 15.8332 14.7799 15.8332 13.8918V6.10685C15.8332 5.21877 15.8332 4.77472 15.6511 4.54666C15.4928 4.34832 15.2527 4.23293 14.9989 4.2332C14.7071 4.23352 14.3604 4.51091 13.6669 5.06569Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const PlayerNext = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.8332 4.16602V15.8327M6.33276 14.933L11.1984 11.0405C11.6433 10.6846 11.8657 10.5067 11.9462 10.2917C12.0168 10.1032 12.0168 9.89551 11.9462 9.70702C11.8657 9.49204 11.6433 9.31409 11.1984 8.95819L6.33276 5.06569C5.63929 4.51091 5.29255 4.23352 5.00074 4.2332C4.74695 4.23293 4.50688 4.34832 4.34855 4.54666C4.1665 4.77472 4.1665 5.21877 4.1665 6.10685V13.8918C4.1665 14.7799 4.1665 15.224 4.34855 15.452C4.50688 15.6504 4.74695 15.7658 5.00074 15.7655C5.29255 15.7652 5.63929 15.4878 6.33276 14.933Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

export const PlayerRefresh = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.6665 8.33333C1.6665 8.33333 3.33732 6.05685 4.6947 4.69854C6.05208 3.34022 7.92783 2.5 9.99984 2.5C14.142 2.5 17.4998 5.85786 17.4998 10C17.4998 14.1421 14.142 17.5 9.99984 17.5C6.58059 17.5 3.69576 15.2119 2.79298 12.0833M1.6665 8.33333V3.33333M1.6665 8.33333H6.6665"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path
            d="M8 7.89906C8 7.48999 8 7.28545 8.07585 7.17126C8.14194 7.07176 8.24311 7.0095 8.35312 7.00064C8.47935 6.99048 8.632 7.10108 8.93729 7.32229L12.5269 9.92324C12.7918 10.1152 12.9243 10.2112 12.97 10.3332C13.01 10.4398 13.01 10.5602 12.97 10.6668C12.9243 10.7888 12.7918 10.8848 12.5269 11.0768L8.93729 13.6777C8.632 13.8989 8.47935 14.0095 8.35312 13.9994C8.24311 13.9905 8.14194 13.9282 8.07585 13.8287C8 13.7145 8 13.51 8 13.1009V7.89906Z"
            fill="white"/>
    </svg>

);
export const PlayerPlus = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10 6.66667V13.3333M6.66667 10H13.3333M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
export const PlayerMessage = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M5.83333 7.08333H10M5.83333 10H12.5M8.06979 15H13.5C14.9001 15 15.6002 15 16.135 14.7275C16.6054 14.4878 16.9878 14.1054 17.2275 13.635C17.5 13.1002 17.5 12.4001 17.5 11V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V16.9463C2.5 17.3903 2.5 17.6123 2.59102 17.7263C2.67019 17.8255 2.79022 17.8832 2.91712 17.8831C3.06302 17.8829 3.23639 17.7442 3.58313 17.4668L5.57101 15.8765C5.9771 15.5517 6.18014 15.3892 6.40624 15.2737C6.60683 15.1712 6.82036 15.0963 7.04101 15.051C7.28972 15 7.54975 15 8.06979 15Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

);
export const PlayerLayout = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.5833 9.16667H10.4167M14.5833 12.5H10.4167M14.5833 5.83333H10.4167M7.5 2.5L7.5 17.5M6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);
export const PlayerBackArrow = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 21V3M3 12H17M17 12L10 5M17 12L10 19" stroke="white" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round"/>
    </svg>
);
export const EpisodesIcon = () => (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.8333 9.16667H10.6667M14.8333 12.5H10.6667M14.8333 5.83333H10.6667M7.75 2.5L7.75 17.5M6.75 2.5H13.75C15.1501 2.5 15.8502 2.5 16.385 2.77248C16.8554 3.01217 17.2378 3.39462 17.4775 3.86502C17.75 4.3998 17.75 5.09987 17.75 6.5V13.5C17.75 14.9001 17.75 15.6002 17.4775 16.135C17.2378 16.6054 16.8554 16.9878 16.385 17.2275C15.8502 17.5 15.1501 17.5 13.75 17.5H6.75C5.34987 17.5 4.6498 17.5 4.11502 17.2275C3.64462 16.9878 3.26217 16.6054 3.02248 16.135C2.75 15.6002 2.75 14.9001 2.75 13.5V6.5C2.75 5.09987 2.75 4.3998 3.02248 3.86502C3.26217 3.39462 3.64462 3.01217 4.11502 2.77248C4.6498 2.5 5.34987 2.5 6.75 2.5Z"
            stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);

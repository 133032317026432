import {
    PasscodeIcon,
    TranslateIcon,
    UserDownIcon,
    TrashIcon,
    UserEditIcon,
    SmilesIcon,
    LogOutIcon,
} from "../../assets/files";
import {ComponentType} from "react";
import {useTranslation} from "react-i18next";
import useResize from "../../hooks/useResize";


export const useEditProfileData = () => {
    const {t} = useTranslation("translations");
    return [
        {name: t("Settings.profileImage"), key: 1, icon: UserDownIcon},
        {name: t("Settings.profileName"), key: 2, icon: UserEditIcon},
        {name: t("Settings.language"), key: 3, icon: TranslateIcon},
        {name: t("Settings.adultSection"), key: 4, icon: PasscodeIcon},
        {name: t("Buttons.deleteProfile"), key: 5, icon: TrashIcon},
    ];
};

export const useEditProfileChildrenData = () => {
    const {t} = useTranslation("translations");
    return [
        {name: t("Settings.contentAge"), key: 1, icon: SmilesIcon},
        {name: t("Settings.language"), key: 2, icon: TranslateIcon},
    ];
};

export interface SettingItem {
    name: string;
    key: number;
    type?: "account" | "images" | "language" | "adults" | null;
    icon: ComponentType; // Type for React component icons
}

export const useSettingsData = (): SettingItem[] => {
    const {t} = useTranslation("translations");
    const {innerWidth} = useResize()
    const isMobile = innerWidth < 860
    return [
        {name: t("Details.account"), key: 0, icon: UserDownIcon,  type: "account"},
        ...(!isMobile ? [{name: t("Settings.profileImage"), key: 1, icon: UserDownIcon}] : []),
        ...(!isMobile ? [{name: t("Settings.profileName"), key: 2, icon: UserEditIcon}] : []),
        {name: t("Settings.language"), key: 3, icon: TranslateIcon, type: "language"},
        {name: t("Settings.adultSection"), key: 4, icon: PasscodeIcon, type: "adults"},
        {name: t("Buttons.deleteProfile"), key: 5, icon: TrashIcon},
        {name: t("Buttons.logOut"), key: 6, icon: LogOutIcon},
    ];
};


export const useSettingsDataChildren = () => {
    const {t} = useTranslation("translations");

    return [
        {name: t("Settings.contentAge"), key: 1, icon: SmilesIcon},
        {name: t("Settings.language"), key: 2, icon: TranslateIcon},
    ];
};

import React, {useEffect, useState} from "react";
import {Button, CustomInput} from "../../index";
import styles from "./styles.module.css"
import {updateProfile} from "../../../services/userAPI";
import {fetchUserProfile} from "../../../store/profile/thunks";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../store";
import {Box} from "@mui/material";
import {setUser} from "../../../store/reducer";
import {useTranslation} from "react-i18next";
import useResize from "../../../hooks/useResize";

interface IProps {
    id?: number | string | null
}

const ProfileName: React.FC<IProps> = (props) => {
    const {id} = props
    const {innerWidth} = useResize()
    const {profile} = useSelector((state: RootState) => state.profile);
    const {t} = useTranslation("translations");
    const dispatch: AppDispatch = useDispatch()
    const [value, setValue] = useState("");
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        profile && setValue(profile?.name)
    }, [profile]);

    const handleUpdate = async () => {
        if (!id) return;
        if (profile?.name === value) return;

        setLoading(true);

        try {
            const res = await updateProfile(id, {name: value});
            if (res) {
                await dispatch(fetchUserProfile(id));
                dispatch(setUser(res));
            }
        } catch (err) {
            console.error("Profile update error:", err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Box className={styles.content}>
            <CustomInput
                name={"name"}
                value={value}
                onChange={(e) => setValue(e.target.value)}
            />
            {innerWidth > 860 &&
              <Button sx={{maxWidth: "420px"}} fullWidth color="secondary" variant="contained" onClick={handleUpdate}
                      isLoading={loading}>
                  {t("Buttons.saveName")}
              </Button>}
        </Box>
    );
};

export default ProfileName;

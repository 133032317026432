import { PlaySquare, Plus} from "../../assets/files";
import {useTranslation} from "react-i18next";

interface DataItem {
    name: string;
    id: number;
}

export const useDataList = () => {
    const { t } = useTranslation("translations");

    return [
        { name: t("MyList.myList"), key: 1, icon: Plus },
        { name: t("MyList.continueWatching"), key: 2, icon: PlaySquare },
    ];
};

export const getDataMyList = (t: (key: string) => string): DataItem[] => [
    { name: t("MyList.myMovies"), id: 0 },
    { name: t("MyList.mySeries"), id: 1 },
    { name: t("MyList.myTVChannels"), id: 2 },
    // { name: t("MyList.mySports"), id: 3 },
];

export const getDataContinueWatch = (t: (key: string) => string): DataItem[] => [
    { name: t("MyList.continueMovies"), id: 0 },
    { name: t("MyList.continueSeries"), id: 1 },
];


interface DataItem {
    name: string;
    id: number;
}

export type IKey = 0 | 1 | 2

export type DataSelectedListType = {
    1: DataItem[];
    2: DataItem[];
    // Add more entries if needed
    // 3: DataItem[];
    // 4: DataItem[];
};
